import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AuftragsarbeitenKlartextPage = () => (
  <Layout>
    <SEO title="AuftragsarbeitenKlartext" />

    <h1 id="ueberschrift">Produktionsangebotsauswahl &emsp; Stand:&nbsp;{new Date().getFullYear()}</h1>

    <p className="note">!!!DIESER BEREICH BEFINDET SICH IM WIEDERAUFBAU!!!</p>

    <p style={{ fontSize: "1.2em", lineHeight: "1.7" }}>
          Kontakt:&ensp;
           <a href="tel:+49-9187-7081220" title="anrufen" style={{ fontSize: "1em"  }}>+49 (0) 91 87 / 70 81 220</a>
           &ensp;oder&ensp;
           <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu Auftragsarbeiten" title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
   </p>

   <p>
     Sie werden zukünftig über diese Seite Informationen
     zu einigen bereits erledigten Auftragsarbeiten finden. Der jeweilige
     Preis für einen Auftrag ist von zu vielen Faktoren abhängig, als dass er pauschal angegeben werden kann.
     Ob Ihre persönliche Vorstellung generell (und aktuell) umgesetzt werden kann, sowie die Kosten für die Erstellung,
     kann bei einem unverbindlichen Gedankenaustausch erörtert werden.
   </p>

   <p>
     Aktuell ist der Großteil des Materials und des Werkzeugs zum Erstellen
     diverser Aufträge eingelagert. Somit hängt die Möglichkeit der Umsetzung Ihres Auftrages
     stark von seiner (Produktions-)Art ab.
   </p>

   {/**
      Nur einige Bilder zu den bereits erstellten Auftragsarbeiten:
       ohne Preis!?!
      als Tooltipp oder Childwindow?!
      Auch Verweis auf Redbubble?!
    */}

    <h2>Basteln und Flechten</h2>
      Stiftehalter, Brotkörbe, Weihnachtssterne, Karten, Magierstäbe, etc.
      <p></p>

    <h2>"Feinschreinern" und Schnitzen</h2>
      Schmuckkästchen, Bilderrahmen, Bogen, Zierpfeile, Magierstäbe, etc.
      <p></p>

    <h2>Handarbeiten</h2>
      Stricken, Häkeln, Knüpfen, Sticken, Buchschutzhüllen, etc.
      <p></p>

    <h2>Näharbeiten</h2>
      Nähen, kürzen oder ausbessern: Larpkleidung, Kostüme, Hosen- &amp; Socken-Stopfen, etc.
      <p></p>

    <h2>Puzzeln</h2>
      Neu puzzeln, Komplettieren und Restaurieren
      <p></p>

    <h2>Werken und Schreinern</h2>
      Truhen, Möbel, (Eck-)Schränke, Steckstühle, Schachtische, Terrarien, etc.
      <p></p>

    <h2>Sonstiges</h2>
      Kettenhemden, Buchbinden, statische Webseiten, Raumgestaltungen, Eigenorganisations-Tafeln (= Kanban- oder Task-Board)
      <br /> und vieles mehr...
      <p></p>

  </Layout>
              )

              export default AuftragsarbeitenKlartextPage
